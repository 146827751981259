import React from 'react';

export default function FloatingCompareButton({ isSolid, handleOnClick, products }) {
  return (
    <>
      {products && products.length > 0 && (
        <>
          <div id={'compare-button'} onClick={() => handleOnClick()} key="compare-key" className={`compare-btn compare ${!isSolid ? 'scroll-float' : ''}`}>
            <span className="icon icon-compare-arrows" />
            {!isSolid && <span className="expand-btn-text">Compare</span>}
            <span className="c-count">{products.length}</span>
          </div>
        </>
      )}
    </>
  );
}
