/* eslint radix: ["error", "as-needed"] */
import React from 'react';
import { ASAP } from '../constants/deliveryType';
import ASAPRemainingTime from './ASAPRemainingTime';
import ScheduleRemainingTime from './ScheduleRemainingTime';

const RemainingTime = ({ setTab, currentTab, initialDeliveryAddressDetails = false }) => {
  if (currentTab === ASAP) {
    return <ASAPRemainingTime setTab={setTab} initialDeliveryAddressDetails={initialDeliveryAddressDetails} />;
  }
  return <ScheduleRemainingTime initialDeliveryAddressDetails={initialDeliveryAddressDetails} />;
};

export default RemainingTime;
