import React, { useContext, useEffect, useState } from 'react';

import { STARTTIMELA, STARTTIMESF } from '../constants/deliveryMessageTiming';
import { DEFAULT_ZIPCODE } from '../constants/default';
import { ASAP } from '../constants/deliveryType';

import appContext from '../Context/appContext';

import { useDeliveryDetails, useHexAsapDeliveryDetails } from '../hooks/app';
import { convertEstimatedTimeToLocal } from '../helpers/MapHelper';
import { splitIntoDateTimeComponents } from '../helpers/timeHelpers';
import useSegment from '../hooks/segment';
import { trackEtaPredictionEvent } from '../helpers/etaPredicationHelper';
import { useSelector } from 'react-redux';

export default function FreightLimit({ currentTab, initialDeliveryAddressDetails = false }) {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude, zoneId, address, placeId }
  //     }
  //   }
  // } = useContext(appContext);
  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude, zoneId, address, placeId } } = useSelector(state => state.user)

  const {
    data: {
      locationId,
      avgDeliveryTime,
      customTextSchedule,
      customTextAsap,
      customTextScheduleTimeSpecificText,
      customTextAsapTimeSpecificText,
      deliveryThreshold,
      isHexLocation,
      asapStartTime,
      asapEndTime
    }
  } = useDeliveryDetails(initialDeliveryAddressDetails, true);

  const { trackEvent } = useSegment();

  const { data: hexAsapDeliveryDetails, isLoading: isHexAsapDeliveryDetailsLoading, isValidating } = useHexAsapDeliveryDetails(locationId, [postcode, latitude, longitude], {
    refreshWhenHidden: false,
    refreshInterval: 0,
    revalidateOnMount: true,
    revalidateOnFocus: true
  });

  const [hidden, setHidden] = useState(false);

  let showLAMessage = false;
  let showSFMessage = false;

  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });

  if (locationId === 1 && currentTime > STARTTIMELA) {
    showLAMessage = true;
  } else if (locationId === 2 && currentTime > STARTTIMESF) {
    showSFMessage = true;
  }

  const newAsapDeliveryDetails = {
    newAsapDeliveryWindow: hexAsapDeliveryDetails?.deliveryDetails?.asap_standard_delivery_window || '',
    newAsapPriorityDeliveryThreshold: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_threshold || '',
    newAsapPriorityDeliveryFee: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_fee || ''
  };

  const { newAsapDeliveryWindow, newAsapPriorityDeliveryThreshold } = newAsapDeliveryDetails || {};

  let asapStandardDeliveryTime = '';
  let asapPriorityDeliveryTime = '';
  let deliveryTypeSpecificMessage = '';

  if (!hidden && currentTab === ASAP && currentTime >= asapStartTime && currentTime <= asapEndTime) {
    if (isHexLocation) {
      asapStandardDeliveryTime = newAsapDeliveryWindow || '';
      asapPriorityDeliveryTime = deliveryThreshold ? newAsapPriorityDeliveryThreshold || '' : '';
    } else if (showLAMessage || showSFMessage) {
      asapStandardDeliveryTime = avgDeliveryTime || '';
      asapPriorityDeliveryTime = deliveryThreshold || '';
    }
  }
  if (!hidden && currentTab) {
    let finalAsapTimeMessage = customTextAsapTimeSpecificText;

    if (isHexLocation && (asapStandardDeliveryTime || asapPriorityDeliveryTime)) {
      // hide asap time specific message for hex locations with either of the asap details
      finalAsapTimeMessage = '';
    }

    deliveryTypeSpecificMessage =
      currentTab === ASAP
        ? finalAsapTimeMessage || customTextAsap || ''
        : customTextScheduleTimeSpecificText || customTextSchedule || '';
  }

  useEffect(() => {
    if (!isHexAsapDeliveryDetailsLoading && !isValidating && hexAsapDeliveryDetails?.deliveryDetails && isHexLocation) {
      const etaTimeChangeDetails = trackEtaPredictionEvent({ hexAsapDeliveryDetails, zoneId, postcode, placeId, latitude, longitude, address });
      if (etaTimeChangeDetails) {
        trackEvent("ETA Predictions", etaTimeChangeDetails);
      }
    }
  }, [isHexAsapDeliveryDetailsLoading, isValidating]);

  useEffect(() => {
    setHidden(!!sessionStorage.getItem('hideFreightLimit'));
  }, []);

  function renderCurrentTime(minutesToAdd) {
    if (minutesToAdd) {
      // round off the to the next 5th minute
      const factor = 1000 * 60 * 5;
      const dateObj = convertEstimatedTimeToLocal(minutesToAdd, true);
      const millis = dateObj.getTime();

      if (dateObj.getMinutes() % 5 === 0) {
        return splitIntoDateTimeComponents(dateObj, true).time12;
      }

      return splitIntoDateTimeComponents(Math.ceil(millis / factor) * factor, true).time12;
    }

    return '';
  }

  function renderDeliveryTime() {
    let standardTime = '';
    let priorityTime = '';

    if (asapPriorityDeliveryTime) {
      if (isHexLocation) {
        priorityTime = renderCurrentTime(asapPriorityDeliveryTime);
      } else if (asapPriorityDeliveryTime) {
        priorityTime = `${asapPriorityDeliveryTime} mins`;
      }
    }

    if (asapStandardDeliveryTime) {
      if (isHexLocation) {
        const standardTimeSplit = asapStandardDeliveryTime.split(' - ');
        standardTime = `${renderCurrentTime(Number(standardTimeSplit[0]))} - ${renderCurrentTime(Number(standardTimeSplit[1]))}`;
      } else if (asapStandardDeliveryTime) {
        standardTime = `${asapStandardDeliveryTime} mins`;
      }
    }

    if (standardTime || asapPriorityDeliveryTime) {
      return (
        <div className="a-timings">
          <i className="icon-timer-fancy timer-icon d-lg-none" />

          <div className="semibold d-lg-none">Get your order fast!</div>

          <div className="timings-inner d-lg-none">
            {standardTime ? (
              <span className="time-holder">
                <>ASAP: </>
                <span className="text-primary">{standardTime}</span>
              </span>
            ) : null}

            {priorityTime ? (
              <span className="time-holder">
                <>Priority ASAP: </>
                <span className="text-primary">{priorityTime}</span>
              </span>
            ) : null}
          </div>

          <div className="timings-inner d-none d-lg-block semi-bold">
            {standardTime ? (
              <span className="time-holder">
                <i className="icon-asap-standard" />
                <> ASAP Delivery: </>
                <span className="text-primary">{`${standardTime}`}</span>
              </span>
            ) : null}

            {asapPriorityDeliveryTime ? (
              <span className="time-holder">
                <i className="icon-asap-priority" />
                <> Priority ASAP Delivery: </>
                <span className="text-primary">{`${priorityTime}`}</span>
              </span>
            ) : null}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="freight-limit gd-freight-limit">
      {renderDeliveryTime()}

      {deliveryTypeSpecificMessage ? (
        <div className="fl-inner">
          <i className="icon-truck pr-2 truck-icon bold align-middle" />

          <span test-id="#freight_limit_text" className="">
            {deliveryTypeSpecificMessage}
          </span>
        </div>
      ) : null}
    </div>
  );
}
