import React, { useState } from 'react';
import appConfig from '../appConfig';
import { ASAP } from '../constants/deliveryType';
import DeliveryTiming from './DeliveryTiming';
import DeliveryTimingSchedule from './DeliveryTimingSchedule';
import FloatingFilterButton from './FloatingFilterButton';
import FreightMessageBanner from './FreightMessageBanner';
import DeliveryTypeToggle from './DeliveryTypeToggle';
import * as WEBSITES from '../constants/website';
import { useDeliveryDetails } from '../hooks/app';
export default function FixedBottomBar({
  scrollDirectionTop,
  tab,
  setTab,
  hideFreightLimit,
  unifiedMenu,
  displayFreightBanner,
  // asapEnabled,
  // scheduleEnabled,
  asapProductsCount,
  scheduledProductsCount,
  hideFilters,
  filters,
  toggleFilterPopup,
  filterApplied,
  setDisplayFreightBanner,
  initialDeliveryAddressDetails = false,
  isSearchPage
}) {
  const [asapCounterEnabled, setAsapCounterEnabled] = useState(false);
  const [noAsapSlots, setNoAsapSlots] = useState(false);
  const [showNextWindow, setShowNextWindow] = useState(true);
  const {
    data: { asapEnabled, scheduleEnabled }
  } = useDeliveryDetails(false, true);
  return (
    <>
      <div className={`fixed-bottom-bar d-lg-none`}>
        <div className={`fbb-content ${!showNextWindow ? 'not-at-top' : ''}`}>
          {tab == ASAP ? (
            <DeliveryTiming
              currentTab={tab}
              setTab={setTab}
              showNextWindow={showNextWindow}
              setShowNextWindow={setShowNextWindow}
            />
          ) : (
            <DeliveryTimingSchedule showNextWindow={showNextWindow} setShowNextWindow={setShowNextWindow} />
          )}
          {hideFreightLimit || (!appConfig.isGrassdoor && unifiedMenu == 1) ? null : displayFreightBanner ? (
            <FreightMessageBanner
              currentTab={tab}
              initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              dismiss={() => setDisplayFreightBanner(false)}
            />
          ) : null}
        </div>
        <div
          className={`bottom-tab ${((!asapEnabled || !scheduleEnabled) && !asapCounterEnabled) || noAsapSlots ? 'without-toggle' : ''
            }`}
        >
          <div className="fb-toggle-wrapper">
            {asapEnabled || scheduleEnabled ? (
              <DeliveryTypeToggle
                currentTab={tab}
                setTab={setTab}
                isAsapDisabled={false}
                isScheduleDisabled={!scheduleEnabled}
                asapProductsCount={asapProductsCount}
                scheduledProductsCount={scheduledProductsCount}
                setAsapCounterEnabled={setAsapCounterEnabled}
                asapCounterEnabled={asapCounterEnabled}
                hideToggle={!asapEnabled || !scheduleEnabled}
                setNoAsapSlots={setNoAsapSlots}
              />
            ) : null}
          </div>
          {isSearchPage ? null : (
            <div className="bottom-right">
              {process.env.NEXT_PUBLIC_APP === WEBSITES.GANJAGODDESS ? (
                <a href="tel:818-308-1074" className="gd-number v-gradient-d no-text-decoration cursor-pointer">
                  <i className="icon icon-telephone-i"></i>(818) 308-1074
                </a>
              ) : null}
              {/* <div
                onClick={() => window.fcWidget.open({ conversationReferenceId: '1', name: 'Payment Issues’' })}
                className="help-icon-info cursor-pointer v-gradient-d"
              >
                <span className="icon icon-livechat-i" />
              </div> */}
              {!hideFilters ? (
                <FloatingFilterButton
                  filters={filters}
                  toggleFilterPopup={toggleFilterPopup}
                  filterApplied={filterApplied}
                  isSolid={true}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
