import React, { useEffect, useState, useRef, useContext } from 'react';

import { useDeliveryDetails } from '../hooks/app';

import appContext from '../Context/appContext';
import notificationTypes from '../constants/modalNotificationConst';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';

const ScheduleRemainingTime = ({ initialDeliveryAddressDetails }) => {
  const {
    data: { scheduleTimer, noScheduleSlotsMessage, deliveryDays },
    mutate
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  const notifications = useSelector(state => state.modal.notifications)
  const dispatchRedux = useDispatch()

  const timeLeft = scheduleTimer;
  const [remainingTime, setRemainingTime] = useState(0);

  const interval = useRef(null);

  const remainingSeconds = remainingTime % 60;
  const remainingMinutes = parseInt(remainingTime / 60, 10) % 60;
  const remainingHours = parseInt(remainingTime / 3600, 10);

  const earliestDay = deliveryDays?.[0]?.day || '';
  const earliestScheduleWindow = deliveryDays?.[0]?.deliveryWindows?.[0]?.slot || '';

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  const getDeliverySlots = () => {
    mutate();
  };

  const renderMinutes = () => {
    let text = '';

    if (remainingMinutes) {
      text = `${remainingMinutes}m:`;
    } else if (remainingHours) {
      text = '0m:';
    }
    return text;
  };

  const renderSeconds = () => {
    let text = '';
    if (remainingSeconds) {
      if (remainingSeconds < 10) {
        text = `0${remainingSeconds}s`;
      } else {
        text = `${remainingSeconds}s`;
      }
    } else if (remainingMinutes) {
      text = '00s';
    }
    return text;
  };

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeLeft || {};
    setRemainingTime(hours * 3600 + minutes * 60 + seconds);
  }, [timeLeft]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else {
        getDeliverySlots();
        clearIntervals();
      }
    };

    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime]);

  const showAvailableDelivery = () => {
    const scheduleSlotOpen = notifications.map(notification => notification.type).includes(notificationTypes.scheduleSlot);
    if (!scheduleSlotOpen) {
      dispatchRedux(pushNotification({ type: notificationTypes.scheduleSlot, data: { noScheduleSlotsMessage, hideRadioButtons: true } }))
    }
  };

  return earliestDay && earliestScheduleWindow ? (
    <div className="text-center p-2 remaining-time" style={{ color: '#a7a7a7' }}>
        <div className="scheduled-delivery-message-handle bold px-2">
          <div>
            Next Available Delivery Window:
            <span className="brand-color mx-1">{`${earliestDay} from ${earliestScheduleWindow}`}</span>
          </div>
          <div>
            <span className="delivery-dates-popup mr-1" onClick={showAvailableDelivery}>
              See all
            </span>
            available delivery times
          </div>
        </div>

      {remainingTime > 0 && remainingHours < 1 && (
        <div className={'semi-bold'}>
          {'You have '}
          <span className="brand-color">
            {`${remainingHours > 0 ? `${remainingHours}h:` : ''}`}
            {renderMinutes()}
            {renderSeconds()}
          </span>
          {' to place your order and avail the above delivery slot'}
        </div>
      )}
    </div>
  ) : null;
};

export default ScheduleRemainingTime;
