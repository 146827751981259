import storageService from "../services/storageService";

export const trackEtaPredictionEvent = ({ hexAsapDeliveryDetails, zoneId, postcode, placeId, latitude, longitude, address }) => {

  const {
    asap_standard_delivery_window: updatedAsapDeliveryWindow = '',
    asap_priority_delivery_threshold: updatedAsapPriorityDeliveryThreshold = '',
    asap_standard_hex6_eta_predictions_id: updatedAsapStandardHex6EtaPredictionsId = '',
    asap_priority_hex6_eta_predictions_id: updatedAsapPriorityHex6EtaPredictionsId = '',
  } = hexAsapDeliveryDetails?.deliveryDetails;
  const splitTime = updatedAsapDeliveryWindow?.split(' - ') || null;
  let prevHexDelieveryDetails = storageService.getHexAsapDeliveryDetails();
  const EtaTimeChangeDetails = {
    zoneId: zoneId,
    zoneName: storageService.getZoneLocalStorage() || null,
    zipcode: postcode,
    placeId: placeId,
    lat: latitude,
    long: longitude,
    address: address,
    asapStandardDeliveryLowerBound: splitTime?.[0] || '',
    asapStandardDeliveryUpperBound: splitTime?.[1] || '',
    asapPriorityDeliveryThreshold: updatedAsapPriorityDeliveryThreshold
  }
  if (prevHexDelieveryDetails) {
    const { asapStandardHex6EtaPredictionsId = '', asapPriorityHex6EtaPredictionsId = '' } = prevHexDelieveryDetails;
    if (asapStandardHex6EtaPredictionsId != updatedAsapStandardHex6EtaPredictionsId || asapPriorityHex6EtaPredictionsId != updatedAsapPriorityHex6EtaPredictionsId) {
      storageService.setHexAsapDeliveryDetails({
        asapStandardHex6EtaPredictionsId: updatedAsapStandardHex6EtaPredictionsId,
        asapPriorityHex6EtaPredictionsId: updatedAsapPriorityHex6EtaPredictionsId
      })
      return EtaTimeChangeDetails;
    }
  }
  else {
    storageService.setHexAsapDeliveryDetails({
      asapStandardHex6EtaPredictionsId: updatedAsapStandardHex6EtaPredictionsId,
      asapPriorityHex6EtaPredictionsId: updatedAsapPriorityHex6EtaPredictionsId
    })
    return EtaTimeChangeDetails;
  }

  return null;
}