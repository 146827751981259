import React, { useContext, useEffect, useState } from 'react'
import { STARTTIMELA, STARTTIMESF } from '../constants/deliveryMessageTiming';
import { ASAP } from '../constants/deliveryType';
import appContext from '../Context/appContext';
import { useDeliveryDetails } from '../hooks/app';

export default function FreightMessageBanner({ dismiss, currentTab, initialDeliveryAddressDetails = false }) {

  const {
    data: {
      locationId,
      customTextSchedule,
      customTextAsap,
      customTextScheduleTimeSpecificText,
      customTextAsapTimeSpecificText,
      isHexLocation,
    }
  } = useDeliveryDetails(initialDeliveryAddressDetails, true);

  let showLAMessage = false;
  let showSFMessage = false;
  const [hidden, setHidden] = useState(false);

  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });

  if (locationId === 1 && currentTime > STARTTIMELA) {
    showLAMessage = true;
  } else if (locationId === 2 && currentTime > STARTTIMESF) {
    showSFMessage = true;
  }

  let asapStandardDeliveryTime = '';
  let asapPriorityDeliveryTime = '';
  let deliveryTypeSpecificMessage = '';

  if (!hidden && currentTab && (showLAMessage || showSFMessage)) {
    let finalAsapTimeMessage = customTextAsapTimeSpecificText;

    if (isHexLocation && (asapStandardDeliveryTime || asapPriorityDeliveryTime)) {
      // hide asap time specific message for hex locations with either of the asap details
      finalAsapTimeMessage = '';
    }

    deliveryTypeSpecificMessage =
      currentTab === ASAP
        ? finalAsapTimeMessage || customTextAsap || ''
        : customTextScheduleTimeSpecificText || customTextSchedule || '';
  }
  useEffect(() => {
    setHidden(!!sessionStorage.getItem('hideFreightLimit'));
  }, []);
  if (!deliveryTypeSpecificMessage)
    return null
  return (
    <div className='freight-message-banner'>
      <span>{deliveryTypeSpecificMessage}</span>
      <button className='btn icon-close' onClick={dismiss}></button>
    </div>
  )
}
