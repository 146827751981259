import React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import appContext from '../Context/appContext';

const AddToCartButton = React.memo(({ isSoldOut, handleProductClick, itemInCart, productCounterType, categoryIndex, productIndex, data, setShowLoader, showLoader }) => {

  // const {
  //   state: {
  //     cart: { cartData: { cart_items: cartItems = [] } = {} }
  //   }
  // } = useContext(appContext);

  const cartItems = useSelector(state => state.cart?.cartData?.cart_items) || []
  const isLoggedIn = useSelector(state => state.user?.isLoggedIn)

  let isItemInCart = false;
  if (data?.product_details_id) {
    isItemInCart = cartItems.find(cartItem => cartItem.product_details_id === data.product_details_id);
  } else if (data?.bundle_id) {
    isItemInCart = cartItems.find(cartItem => cartItem.bundle_id === data.bundle_id);
  }

  if (process.browser && !isSoldOut) {
    return (
      <button
        type="button"
        title="Add to Cart"
        onClick={(e) => { isLoggedIn && !isItemInCart && setShowLoader && setShowLoader(true); handleProductClick(e); }}
        className={`btn add-to-cart new-add-product-button increase-touch-area ${itemInCart || isItemInCart ? 'opacify' : ''}   ${productCounterType != 'count' ? 'hide-button' : ''
          }`}
        data-testid="#add-to-cart-button"
        test-id={`#product_card_${categoryIndex}${productIndex}`}
        disabled={isSoldOut}
      >
        <span className={'icon-plus c-icon text-primary inner-icon'} />
        {/* <span
          className={'c-icon text-primary inner-icon product-add-fill-btn'}>
            ADD
        </span> */}

      </button>
    );
  }
  return null;
});

export default AddToCartButton;
