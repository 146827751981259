import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import appConfig from '../appConfig';

const NoProductAvailable = ({
  asapProductsCount = 0,
  scheduledProductsCount = 0,
  currentTab,
  setTab,
  applyFilters,
  asapEnabled,
  scheduleEnabled,
  unified_menu,
  className = '',
  setTrack
}) => {

  const router = useRouter();
  const { pathname } = router;

  const conditions = ["shop", "brands", "category"];

  const isAnyTabDisabled = !asapEnabled || !scheduleEnabled;

  const renderAsapButtonName = () => conditions.some(el => pathname.includes(el)) ? `Visit ASAP menu` : 'Visit homepage';

  const renderTextOnPageConditions = () => conditions.some(el => pathname.includes(el)) ? `or visit ASAP menu` : 'or visit our homepage';

  const changeTab = () => {
    currentTab === ASAP ? setTab(SCHEDULE) : setTab(ASAP);
  };

  useEffect(() => {
    setTrack ? setTrack([], null, true) : null;
  }, [])

  return (
    <div className={`no-product-available ${className}`}>

      <div style={!appConfig.isGrassdoor ? { filter: "grayscale(100%)" } : {}}>
        <FilterEmptyState
          isAnyTabDisabled={isAnyTabDisabled}
          unified_menu={unified_menu}
          currentTab={currentTab}
          scheduledProductsCount={scheduledProductsCount}
          asapProductsCount={asapProductsCount}
        />
      </div>

      <div>
        {unified_menu === 1
          ? `Please clear your filters to find what you're looking for.`
          : isAnyTabDisabled
            ? "Please clear your filters to find what you're looking for."
            : (scheduledProductsCount === 0 && asapProductsCount === 0)
              ? <div>Please reduce / reset filter selection to find what you're looking for.</div>
              : `Please search our expanded ${currentTab === ASAP ? "Scheduled" : "ASAP"} menu selection to find what you're looking for`}
      </div>
      {isAnyTabDisabled || (scheduledProductsCount === 0 && asapProductsCount === 0)
        ? null
        : (
          <button className="btn btn-primary" type onClick={() => changeTab()}>
            Show {scheduledProductsCount === 0 ? "ASAP" : "Scheduled"} Menu
          </button>
        )
      }

    </div>
  );

};

const FilterEmptyState = ({ isAnyTabDisabled, unified_menu, currentTab, scheduledProductsCount = 0, asapProductsCount = 0 }) => {

  if (isAnyTabDisabled || unified_menu === 1) {
    return (
      <>
        <img src="/static/images/no-products.svg" width="300px" alt="" />
        <h4>No products here!</h4>
      </>
    )
  }

  return (
    (currentTab === ASAP && (scheduledProductsCount && scheduledProductsCount !== 0)) ? (
      <>
        <img src="/static/images/no-products-asap.svg" width="300px" alt="" />
        <h4>{scheduledProductsCount} {scheduledProductsCount === 1 ? "Product" : "Products"} in Scheduled Menu</h4>
      </>
    ) : (currentTab === SCHEDULE && (asapProductsCount && asapProductsCount !== 0)) ? (
      <>
        <img src="/static/images/no-products-scheduled.svg" width="300px" alt="" />
        <h4>{asapProductsCount} {asapProductsCount === 1 ? "Product" : "Products"} in ASAP Menu</h4>
      </>
    ) : (
      <>
        <img src="/static/images/no-products.svg" width="300px" alt="" />
        <h4>No products here!</h4>
      </>
    )
  )
}

export default NoProductAvailable;
