import React from 'react';
import Link from 'next/link';

const BreadCrumbs = ({ paths = [] }) => {
  return (
    <div className="breadcrumb-parent d-lg-block">
      {paths.map((item, index) => {
        return (
          <div className="d-inline t-text">
            {index === 0 ? null : (
              <span>
                &nbsp;
                {' / '}
              </span>
            )}
            <i className={`text-primary ${item.icon}`} />
            &nbsp;
            <Link prefetch={false} href={{ pathname: item.path }} as={{ pathname: item.path }}>
              <a href={item.path} className={`footer-links ${item.path ? '' : 'disable-click'}`}>
                {item.title}
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
